import { ReactMarkdown } from "react-markdown/lib/react-markdown";

type RecipeTextProps = {
  text: string;
};

export const RecipeText: React.FC<RecipeTextProps> = (props) => {
  const { text } = props;

  // put anything embraced by '\[\[' and '\]\]' into a <b> tag
  //   const boldText = text.replace(/\\\[\\\[(.*?)\\\]\\\]/g, "<b>$1</b>");

  const boldText = text
    .replace(/\\\[\\\[/g, "**")
    .replace(/\\\]\\\]/g, "**")
    .replace(/\[\[/g, "**")
    .replace(/\]\]/g, "**");

  //return rendered text
  //   return <p>{text}</p>;
  return <ReactMarkdown children={boldText} />;
};
