import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useStore } from "../store";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useMutation } from "@tanstack/react-query";
import { API_URL } from "../config";
import { useState } from "react";
import { recipeData } from "./RecipeViewData";
import {
  rubrikItemWithoutCounts,
  useInvalidateRubrikQuery,
  useRubrikQuery,
} from "../hooks/useRubrikQuery";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
  Alert,
  FormControl,
  FormHelperText,
  InputLabel,
  useMediaQuery,
  useTheme,
} from "@mui/material";

type IngredientCreateDialogProps = {
  registerNewIngredient: (
    index: number,
    data: Pick<recipeData["zutaten"][number], "rohstoff_ID" | "rohstoff">
  ) => void;
};

let defaultVegetarian = true;
let defaultVegan = false;

export const IngredientCreateDialog: React.FC<IngredientCreateDialogProps> = (
  props
) => {
  const { registerNewIngredient } = props;
  const open = useStore((state) => state.ingredientCreatorOpen);
  const value = useStore((state) => state.ingredientCreatorValue);
  const index = useStore((state) => state.ingredientCreatorIndex);
  const setOpen = useStore((state) => state.setIngredientCreatorOpen);
  const setValue = useStore((state) => state.setIngredientCreatorValue);
  const addMessage = useStore((state) => state.addSnackbarMessage);
  const invalidateRohstoffQuery = useInvalidateRubrikQuery("rohstoff");
  const [vegetarian, setVegetarian] = useState(defaultVegetarian);
  const [vegan, setVegan] = useState(defaultVegan);
  const [note, setNote] = useState("");
  const [rohstoffgruppe, setRohstoffgruppe] = useState("");
  const rohstoffgruppenQuery = useRubrikQuery<rubrikItemWithoutCounts>(
    "rohstoffgruppen",
    true
  );
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const postIngredient = useMutation(
    async (values: {
      value: string;
      note: string;
      vegetarian: boolean;
      vegan: boolean;
      rohstoffgruppe: string;
    }) => {
      const formData = new FormData();
      formData.append("ingredientName", values.value);
      formData.append("ingredientNote", values.note);
      formData.append("gruppe", values.rohstoffgruppe);
      formData.append("fleisch", values.vegetarian ? "1" : "2");
      formData.append("tiereiweiss", values.vegan ? "1" : "2");
      const response = await fetch(`${API_URL}rohstoff/post.php`, {
        method: "POST",
        body: formData,
        credentials: "include",
      });
      const json:
        | { error: true; errorCode: number; errorDetail?: string }
        | { error: false; enterID: string; enterName: string } =
        await response.json();

      if (json.error) {
        let errorMessage: string;
        switch (json.errorCode) {
          case 1:
            errorMessage = "Ingredient name already exists";
            break;
          default:
            errorMessage = `Errorcode=${json.errorCode} ${json.errorDetail}`;
        }
        throw new Error(errorMessage);
      }
      return json;
    },
    {
      onError: (error) => {
        if (error instanceof Error) {
          addMessage(error.message, 10000, "error");
        } else addMessage("Failed to save ingredient", 10000, "error");
      },
      onSuccess: (data) => {
        invalidateRohstoffQuery();
        addMessage("Saved ingredient", 4000, "success");
        registerNewIngredient(index, {
          rohstoff: data.enterName,
          rohstoff_ID: data.enterID,
        });
        handleClose();
      },
    }
  );

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    postIngredient.mutate({ value, note, vegetarian, vegan, rohstoffgruppe });
  };

  const handleClose = () => {
    setOpen(false);
    setValue("");
    setVegan(defaultVegan);
    setVegetarian(defaultVegetarian);
    setNote("");
    setRohstoffgruppe("");
  };

  let formValid = true;
  if (!rohstoffgruppe) formValid = false;
  if (!value) formValid = false;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      title="neue Zutat"
      fullScreen={fullScreen}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>Neue Zutat erstellen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Bitte ein paar Informationen zu der neuen Zutat eintragen.
          </DialogContentText>
          {!rohstoffgruppenQuery.isSuccess ? (
            <Alert severity="info">{rohstoffgruppenQuery.status}</Alert>
          ) : (
            <FormGroup>
              <TextField
                fullWidth
                autoFocus
                margin="dense"
                id="name"
                value={value}
                onChange={(event) => setValue(event.target.value)}
                label="Rohstoffname"
                type="text"
                variant="standard"
              />
              <TextField
                fullWidth
                margin="dense"
                id="note"
                label="Beschreibung"
                type="text"
                variant="standard"
                size="small"
                value={note}
                onChange={(event) => setNote(event.target.value)}
              />
              <FormControl error={rohstoffgruppe === ""} margin="normal">
                <InputLabel id="rohstoffgruppeLabel">Gruppe</InputLabel>
                <Select
                  labelId="rohstoffgruppeLabel"
                  fullWidth
                  label="Gruppe"
                  value={rohstoffgruppe}
                  onChange={(event) =>
                    setRohstoffgruppe(event.target.value as string)
                  }
                >
                  {rohstoffgruppenQuery.data?.map((rohstoffgruppe) => (
                    <MenuItem key={rohstoffgruppe.id} value={rohstoffgruppe.id}>
                      {rohstoffgruppe.name}
                    </MenuItem>
                  ))}
                </Select>
                {rohstoffgruppe === "" && (
                  <FormHelperText>Bitte eine Gruppe auswählen</FormHelperText>
                )}
              </FormControl>
              <FormControlLabel
                control={
                  <Switch
                    checked={vegetarian}
                    onChange={() => setVegetarian(!vegetarian)}
                  />
                }
                label="vegetarisch"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={!vegetarian ? false : vegan}
                    disabled={!vegetarian}
                    onChange={() => setVegan(!vegan)}
                  />
                }
                label="vegan"
              />
            </FormGroup>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type="submit"
            disabled={!rohstoffgruppenQuery.isSuccess || !formValid}
          >
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
