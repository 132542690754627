import { UseQueryResult } from "@tanstack/react-query";
import { recipeData } from "./RecipeViewData";
import LinearProgress from "@mui/material/LinearProgress";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { BASE_URL } from "../config";
import { IngredientTable } from "./IngredientTable";
import Chip from "@mui/material/Chip";
import { RecipeText } from "./RecipeText";
import Divider from "@mui/material/Divider";
import { useStore } from "../store";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import RecipeRatingEditor from "./RecipeRating/RecipeRatingEditor";
import RecipeRatingAverage from "./RecipeRating/RecipeRatingAverage";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

type RecipeViewProps = {
  recipeId: string;
  recipeQuery: UseQueryResult<recipeData, unknown>;
};

const metaData: { label: string; dataIndex: keyof recipeData }[] = [
  { label: "Gang", dataIndex: "gang" },
  { label: "Kategorie", dataIndex: "kategorie" },
  { label: "Land", dataIndex: "land" },
  { label: "Erstellt am", dataIndex: "datum" },
  { label: "von", dataIndex: "login" },
  { label: "Portionen", dataIndex: "personen" },
  { label: "Kalorien", dataIndex: "kalorien" },
];

export const RecipeView: React.FC<RecipeViewProps> = (props) => {
  const { recipeQuery } = props;
  const currentUser = useStore((state) => state.user);

  if (recipeQuery.isLoading) return <LinearProgress />;
  if (recipeQuery.isError) return <Alert severity="error">ERROR</Alert>;
  if (!recipeQuery.data) return <Alert severity="error">NO DATA</Alert>;

  const recipe = recipeQuery.data;

  const hasImage = !!recipe.image && recipe.image.length > 0;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={hasImage ? 9 : 12}>
        <h2>{recipe.titel}</h2>
        <h4>{recipe.bemerkung}</h4>

        {metaData.map((meta) => {
          const data = recipe[meta.dataIndex];
          if (!data || typeof data !== "string" || data.length < 1) return null;
          return (
            <Chip
              style={{ marginRight: 10 }}
              variant="outlined"
              key={meta.label}
              label={
                <>
                  <Typography variant="caption">{meta.label}</Typography> {data}
                </>
              }
            />
          );
        })}
      </Grid>
      {hasImage ? (
        <Grid item xs={12} md={3}>
          <img
            src={`${BASE_URL}${recipe.image}`}
            alt={recipe.titel}
            style={{ width: "100%" }}
          />
        </Grid>
      ) : null}
      {currentUser?.login.toLowerCase() === recipe.login.toLowerCase() ? (
        <Grid item xs={12}>
          <Link component={RouterLink} to={`/recipes/${recipe.rezept_ID}/edit`}>
            <DriveFileRenameOutlineIcon /> Dein Rezept bearbeiten
          </Link>
        </Grid>
      ) : null}
      <Grid item md={6}>
        <RecipeRatingEditor recipeId={recipe.rezept_ID} />
      </Grid>
      <Grid item md={6}>
        <RecipeRatingAverage recipeId={recipe.rezept_ID} />
      </Grid>
      <Grid item xs={12}>
        {typeof recipe.zubereitungszeit === "number" &&
        !!recipe.zubereitungszeit ? (
          <Typography variant="body1" gutterBottom>
            <Typography variant="caption">Zubereitungszeit </Typography>
            {recipe.zubereitungszeit} Minuten
          </Typography>
        ) : null}
        <RecipeText text={recipe.zubereitung} />
        {recipe.tip && recipe.tip.length ? (
          <>
            <Divider>
              <Typography variant="caption">Tip</Typography>
            </Divider>
            <RecipeText text={recipe.tip} />
          </>
        ) : null}
      </Grid>

      <IngredientTable recipe={recipe} />
    </Grid>
  );
};
