import { useQuery } from "@tanstack/react-query";
import { API_URL } from "../config";
import { useStore } from "../store";
import { RecipeList } from "./RecipeList";

export type Paginated<T> = {
  data: T[];
  total: number;
  skip: number;
  limit: number;
};

type recipeMeta = {
  /** actually an integer in the string */
  rezept_ID: string;
  login: string;
  /** full pixel representation of the icon */
  icon: string | null;
  titel: string;
  /** integer of language id in string */
  rezept_LNG: string;
  bemerkung: string | null;
  titel_firstletter: string;
  /** assumably the first letter language id */
  rezept_FL_LNG: string;
  ingredients: ingredientMeta[];
  benutzer_ID: string;
  gang_ID: string;
  land_ID: string | null;
  kategorie_ID: string;
  avgStars: number | null;
  starCount: number;
  myStars: number | null;
};

type ingredientMeta = {
  rohstoff: string;
  icon_ID: string;
  icon_cols: number;
  icon_rows: number;
  icon_src: string;
};

export type recipeListResponse = Paginated<recipeMeta>;

export const RecipeListData = () => {
  const languageId = useStore((state) => state.user?.language_ID);
  const recipeQuery = useQuery(
    ["recipes", languageId],
    async () => {
      const response = await fetch(
        `${API_URL}recipes/list.php?languageId=${languageId}&limit=3000&skip=0`,
        { credentials: "include" }
      );
      const json: recipeListResponse = await response.json();
      return json;
    },
    {
      enabled: typeof languageId !== "undefined",
      staleTime: 1000 * 60,
    }
  );

  return <RecipeList recipeQuery={recipeQuery} />;
};
