import { MenuItem, Select } from "@mui/material";
import type { QueryObserverSuccessResult } from "@tanstack/react-query";
import type { UseFormRegisterReturn } from "react-hook-form";
import {
  rubrikItemWithoutCounts,
  useRubrikQuery,
} from "../hooks/useRubrikQuery";
import React from "react";

type UnitSelectorProps =
  UseFormRegisterReturn<`zutaten.${number}.einheit_ID`> & {
    defaultValue?: string;
  };

export const UnitSelector = React.forwardRef(
  (props: UnitSelectorProps, ref) => {
    const { defaultValue, name, onBlur, onChange } = props;
    const einheitQuery = useRubrikQuery<rubrikItemWithoutCounts>(
      "einheit",
      true
    );

    if (einheitQuery.isLoading) return <div>Loading...</div>;

    return (
      <Select
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        ref={ref}
        defaultValue={defaultValue}
        size="small"
        fullWidth
        variant="standard"
        renderValue={(selected) => {
          // shows the abbreviation if it exists, otherwise the name
          const selectedEinheit = einheitQuery.data?.find(
            (einheit) => einheit.id === selected
          );
          if (!selectedEinheit) return "-";
          return selectedEinheit.abbreviation ?? selectedEinheit.name;
        }}
        // autoWidth
      >
        <MenuItem value="0">-</MenuItem>
        {einheitQuery.data?.map((einheit) => (
          <MenuItem key={einheit.id} value={einheit.id}>
            {einheit.name}
          </MenuItem>
        ))}
      </Select>
    );
  }
);
