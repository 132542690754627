import { RubrikFilter } from "./RubrikFilter";
import { useCallback, useState } from "react";
import { rubrikItem, useRubrikQuery } from "../hooks/useRubrikQuery";
import { storeType, useStore } from "../store";

export type rubrikType =
  | "kategorie"
  | "land"
  | "gang"
  | "benutzer"
  | "einheit"
  | "rohstoff"
  | "rohstoffgruppen";

export type RubrikFilterDataProps = {
  rubrik: rubrikType;
};

export const RubrikFilterData: React.FC<RubrikFilterDataProps> = (props) => {
  const { rubrik } = props;
  const [open, setOpen] = useState(false);
  const rubrikQuery = useRubrikQuery<rubrikItem>(rubrik, open);

  let rubrikKey: keyof Pick<
    storeType,
    | "listFilterCategoryId"
    | "listFilterCountryId"
    | "listFilterGangId"
    | "listFilterLogin"
  >;
  let rubrikSetter: keyof Pick<
    storeType,
    | "setListFilterCategoryId"
    | "setListFilterCountryId"
    | "setListFilterGangId"
    | "setListFilterLogin"
  >;
  switch (rubrik) {
    case "kategorie":
      rubrikKey = "listFilterCategoryId";
      rubrikSetter = "setListFilterCategoryId";
      break;
    case "land":
      rubrikKey = "listFilterCountryId";
      rubrikSetter = "setListFilterCountryId";
      break;
    case "gang":
      rubrikKey = "listFilterGangId";
      rubrikSetter = "setListFilterGangId";
      break;
    case "benutzer":
      rubrikKey = "listFilterLogin";
      rubrikSetter = "setListFilterLogin";
      break;
    default:
      throw new Error(`Invalid rubrik ${rubrik}`);
  }

  const setPage = useStore((state) => state.setListPage);
  const filterRubrik = useStore((state) => state[rubrikKey]);
  const setFilterRubrik = useStore((state) => state[rubrikSetter]);
  const onChange = useCallback(
    (
      event: React.SyntheticEvent<Element, Event>,
      newValue: rubrikItem | null
    ) => {
      setPage(0);
      setFilterRubrik(newValue);
    },
    [setPage, setFilterRubrik]
  );

  return (
    <RubrikFilter
      rubrikQuery={rubrikQuery}
      rubrik={rubrik}
      setOpen={setOpen}
      onChange={onChange}
      currentValue={filterRubrik}
    />
  );
};
