import { useQuery } from "@tanstack/react-query";
import { useStore } from "../store";
import { API_URL } from "../config";
import { recipeData } from "../components/RecipeViewData";

export const useRecipeQuery = (recipeId: string | undefined) => {
  const languageId = useStore((state) => state.user?.language_ID);
  const recipeQuery = useQuery(
    ["recipe", languageId, recipeId],
    async () => {
      const response = await fetch(
        `${API_URL}recipes/get.php?languageId=${languageId}&id=${recipeId}`,
        { credentials: "include" }
      );
      const json: recipeData = await response.json();
      return json;
    },
    {
      staleTime: Infinity,
      enabled:
        typeof recipeId !== "undefined" && typeof languageId !== "undefined",
    }
  );

  return recipeQuery;
};
