import Autocomplete from "@mui/material/Autocomplete";
import { Control, Controller, UseFormRegister } from "react-hook-form";
import { recipeData } from "./RecipeViewData";
import { rubrikItem, rubrikItemWithoutCounts } from "../hooks/useRubrikQuery";
import TextField from "@mui/material/TextField";
import { FilterOptionsState } from "@mui/material/useAutocomplete";

type ReactSelectorProps = {
  control: Control<recipeData, any>;
  name: keyof recipeData | string;
  options: rubrikItem[];
  addLabel?: boolean;
  filterOptions?:
    | ((
        options: rubrikItemWithoutCounts[],
        state: FilterOptionsState<rubrikItemWithoutCounts>
      ) => rubrikItemWithoutCounts[])
    | undefined;
};

export const RubrikSelector: React.FC<ReactSelectorProps> = (props) => {
  const { options, control, name, addLabel = true, filterOptions } = props;

  const registerProps = control.register(name as keyof recipeData, {
    required: true,
  });

  return (
    <Controller
      render={(props) => {
        const { field, fieldState } = props;
        const { onChange, value } = field;
        const { error } = fieldState;

        const selected =
          typeof value === "string"
            ? options.find((o) => o.name === value)
            : typeof value === "undefined"
            ? null
            : (value as unknown as rubrikItem);

        return (
          <Autocomplete
            selectOnFocus
            clearOnBlur
            size="small"
            options={options}
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            getOptionLabel={(option) => {
              return option.name;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                ref={registerProps.ref}
                helperText={!!error && !selected && "Bitte Auswahl treffen."}
                error={!!error && !selected}
                onBlur={registerProps.onBlur}
                label={
                  addLabel
                    ? name.charAt(0).toUpperCase() + name.slice(1)
                    : undefined
                }
              />
            )}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              );
            }}
            onChange={(e, v) => {
              onChange(v);
            }}
            value={selected}
            filterOptions={filterOptions}
          />
        );
      }}
      // @ts-expect-error bad...
      name={name}
      control={control}
    />
  );
};
