import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { produce, Immutable } from "immer";
import { User } from "../hooks/checkAuth";
import type { rubrikItem } from "../hooks/useRubrikQuery";
import { AlertColor } from "@mui/material/Alert";

export type storeType = {
  user: User | undefined;
  authStatus: "initial" | "loading" | "done" | "error";
  authError: string | undefined;
  authenticated: boolean | null;
  listPage: number;
  listItemsPerPage: number;
  listSearchText: string;
  listMinStarMy: number | null;
  listMinStarAvg: number | null;
  listFilterCategoryId: rubrikItem | null;
  listFilterGangId: rubrikItem | null;
  listFilterCountryId: rubrikItem | null;
  listFilterLogin: rubrikItem | null;
  snackbarMessage: {
    message: string;
    duration: number;
  } | null;
  snackbarType: AlertColor;
  ingredientCreatorIndex: number;
  ingredientCreatorValue: string;
  ingredientCreatorOpen: boolean;
  addSnackbarMessage: (
    message: string,
    /** Message duration in milliseconds before the message is hidden again */
    duration: number,
    style?: AlertColor
  ) => void;
  removeSnackbarMessage: () => void;
  setAuth: (
    authenticated: storeType["authenticated"],
    authError: storeType["authError"],
    user: storeType["user"]
  ) => void;
  setAuthStatus: (status: storeType["authStatus"]) => void;
  setListPage: (page: number) => void;
  setListItemsPerPage: (itemsPerPage: number) => void;
  setListSearchText: (searchText: string) => void;
  setListStarMinMy: (minStar: number | null) => void;
  setListStarMinAvg: (minStar: number | null) => void;
  setListFilterCategoryId: (categoryId: rubrikItem | null) => void;
  setListFilterGangId: (gangId: rubrikItem | null) => void;
  setListFilterCountryId: (countryId: rubrikItem | null) => void;
  setListFilterLogin: (login: rubrikItem | null) => void;
  setIngredientCreatorOpen: (open: boolean) => void;
  setIngredientCreatorValue: (value: string) => void;
  setIngredientCreatorIndex: (index: number) => void;
};

export const useStore = create<storeType>()(
  devtools((set) => ({
    user: undefined,
    authStatus: "initial",
    authError: undefined,
    authenticated: null,
    listPage: 0,
    listItemsPerPage: 20,
    listSearchText: "",
    listMinStarMy: null,
    listMinStarAvg: null,
    listFilterCategoryId: null,
    listFilterGangId: null,
    listFilterCountryId: null,
    listFilterLogin: null,
    snackbarMessage: null,
    snackbarType: "success",
    ingredientCreatorOpen: false,
    ingredientCreatorValue: "",
    ingredientCreatorIndex: -1,
    addSnackbarMessage: (message, duration, style = "success") =>
      set(
        produce<storeType>((draft) => {
          draft.snackbarMessage = { message, duration };
          draft.snackbarType = style;
        })
      ),
    removeSnackbarMessage: () =>
      set(
        produce<storeType>((draft) => {
          draft.snackbarMessage = null;
        })
      ),
    setListFilterCategoryId: (categoryId: rubrikItem | null) =>
      set(
        produce<storeType>((draft) => {
          draft.listFilterCategoryId = categoryId;
        })
      ),
    setListFilterGangId: (gangId: rubrikItem | null) =>
      set(
        produce<storeType>((draft) => {
          draft.listFilterGangId = gangId;
        })
      ),
    setListFilterCountryId: (countryId: rubrikItem | null) =>
      set(
        produce<storeType>((draft) => {
          draft.listFilterCountryId = countryId;
        })
      ),
    setListFilterLogin: (login: rubrikItem | null) =>
      set(
        produce<storeType>((draft) => {
          draft.listFilterLogin = login;
        })
      ),
    setAuth: (
      authenticated: storeType["authenticated"],
      authError: storeType["authError"],
      user: storeType["user"]
    ) =>
      set(
        produce<storeType>((draft) => {
          draft.authenticated = authenticated;
          draft.authError = authError;
          draft.user = user;
          draft.authStatus = typeof authError !== "string" ? "done" : "error";
        })
      ),
    setAuthenticated: (authenticated: storeType["authenticated"]) =>
      set(
        produce<storeType>((draft) => {
          draft.authenticated = authenticated;
        })
      ),

    setAuthStatus: (status: storeType["authStatus"]) =>
      set(
        produce<storeType>((draft) => {
          draft.authStatus = status;
        })
      ),
    setUser: (user: storeType["user"]) =>
      set(
        produce<storeType>((draft) => {
          draft.user = user;
        })
      ),
    setListPage: (page: number) =>
      set(
        produce<storeType>((draft) => {
          draft.listPage = page;
        })
      ),
    setListItemsPerPage: (itemsPerPage: number) =>
      set(
        produce<storeType>((draft) => {
          draft.listItemsPerPage = itemsPerPage;
        })
      ),
    setListSearchText: (searchText: string) =>
      set(
        produce<storeType>((draft) => {
          draft.listSearchText = searchText;
        })
      ),
    setListStarMinMy: (minStar: number | null) =>
      set(
        produce<storeType>((draft) => {
          draft.listMinStarMy = minStar;
        })
      ),
    setListStarMinAvg: (minStar: number | null) =>
      set(
        produce<storeType>((draft) => {
          draft.listMinStarAvg = minStar;
        })
      ),
    setIngredientCreatorOpen: (open: boolean) =>
      set(
        produce<storeType>((draft) => {
          draft.ingredientCreatorOpen = open;
        })
      ),
    setIngredientCreatorValue: (value: string) =>
      set(
        produce<storeType>((draft) => {
          draft.ingredientCreatorValue = value;
        })
      ),
    setIngredientCreatorIndex: (index: number) =>
      set(
        produce<storeType>((draft) => {
          draft.ingredientCreatorIndex = index;
        })
      ),
  }))
);
