import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useState } from "react";

type IngredientAmountProps = {
  amount: string | null;
  rohstoff: string;
  setPercentage: (percentage: string) => void;
  percentage: number;
};

export const IngredientAmount: React.FC<IngredientAmountProps> = (props) => {
  const { amount, setPercentage, percentage, rohstoff } = props;
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(amount ?? "");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setValue(amount ?? "");
    setOpen(false);
  };

  const hasAmount = typeof amount === "string" && parseFloat(amount) > 0;

  return (
    <>
      <span
        style={{
          cursor: hasAmount ? "pointer" : "default",
          fontWeight: "bold",
        }}
        onClick={() => {
          if (typeof amount !== "string") return;
          handleClickOpen();
        }}
      >
        {/* round to 2 decimal places. do not show zeros */}
        {hasAmount
          ? (Math.round(parseFloat(amount) * percentage) / 100)
              .toFixed(10)
              .replace(/\.?0+$/, "")
          : null}
      </span>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Rezept umrechnen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Das Rezept so umrechnen, dass die eingegebene Menge von {rohstoff}{" "}
            genutzt wird.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="amount"
            label="Menge"
            type="number"
            fullWidth
            variant="standard"
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              setPercentage(
                (
                  (100 * parseFloat(value)) /
                  parseFloat(amount ?? "0")
                ).toString()
              );
              handleClose();
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
