import Tab from "@mui/material/Tab";
import { useLogout } from "../hooks/useLogout";
import { useStore } from "../store";

export const LogoutButton = () => {
  const authenticated = useStore((state) => state.authenticated);
  const user = useStore((state) => state.user);

  const { logoutNow, loading } = useLogout();

  if (authenticated === false) return null;

  return (
    <Tab
      label="Logout"
      value="logout"
      disabled={loading}
      onClick={() => logoutNow()}
    />
    // <Button variant="contained" disabled={loading} onClick={() => logoutNow()}>
    //   Logout {user?.login}
    // </Button>
  );
};
