import { useQuery } from "@tanstack/react-query";
import { useStore } from "../../store";
import { API_URL } from "../../config";

export type starResponse = {
  success: true;
  rezept_ID: number;
  myStars: number | null;
  avgStars: number | null;
  starCount: number;
  postMode: "insert" | "update" | "delete";
};

const useRecipeRating = (recipeId: string) => {
  const addMessage = useStore((state) => state.addSnackbarMessage);
  const fetchRecipeRating = async () => {
    const response = await fetch(
      `${API_URL}stars/getMy.php?rezept_ID=${recipeId}`,
      { credentials: "include" }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch recipe rating");
    }

    const jsonResponse: starResponse = await response.json();

    if (!jsonResponse.success) {
      throw new Error(
        "Failed to fetch recipe rating, got non success json response"
      );
    }
    return jsonResponse;
  };

  return useQuery(["recipeRating", recipeId.toString()], fetchRecipeRating, {
    onError: (e) => {
      if (e instanceof Error) {
        addMessage(e.message, 5000, "error");
      } else {
        addMessage("An unknown error occurred", 5000, "error");
      }
    },
  });
};

export default useRecipeRating;
