import { Control, Controller } from "react-hook-form";
import {
  rubrikItem,
  rubrikItemWithoutCounts,
  useRubrikQuery,
} from "../hooks/useRubrikQuery";
import { recipeData } from "./RecipeViewData";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useStore } from "../store";

type IngredientInputProps = {
  control: Control<recipeData, any>;
  name: string;
  /** Index of the zutat item in the list */
  index: number;
};

const filter = createFilterOptions<rubrikItemWithoutCounts>();

export const IngredientInput: React.FC<IngredientInputProps> = (props) => {
  const { control, name, index } = props;
  const setCreateDialogOpen = useStore(
    (state) => state.setIngredientCreatorOpen
  );
  const setCreateDialogValue = useStore(
    (state) => state.setIngredientCreatorValue
  );
  const setCreateDialogIndex = useStore(
    (state) => state.setIngredientCreatorIndex
  );

  const rohstoffQuery = useRubrikQuery<rubrikItemWithoutCounts>(
    "rohstoff",
    true
  );

  if (rohstoffQuery.isLoading) {
    return <div>Loading...</div>;
  } else if (rohstoffQuery.isError) {
    return (
      <div>
        Error:{" "}
        {rohstoffQuery.error instanceof Error
          ? rohstoffQuery.error.message
          : `Konnte keine Rohstoffdaten laden.`}
      </div>
    );
  }

  const options = rohstoffQuery.data;

  return (
    <Controller
      render={(props) => {
        const { field } = props;
        const { onChange, value } = field;

        const selected =
          typeof value === "string"
            ? value === ""
              ? null
              : options.find((o) => o.id === value) ?? null
            : typeof value === "undefined"
            ? null
            : value !== null && "id" in value && value.id !== ""
            ? (value as unknown as rubrikItem)
            : null;

        return (
          <Autocomplete
            freeSolo
            selectOnFocus
            clearOnBlur
            size="small"
            options={options}
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            getOptionLabel={(option) => {
              return typeof option === "string" ? option : option.name;
            }}
            renderInput={(params) => {
              return (
                <TextField {...params} label={undefined} variant="standard" />
              );
            }}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              );
            }}
            onChange={(e, v) => {
              if (
                typeof v === "object" &&
                v !== null &&
                "inputValue" in v &&
                typeof v.inputValue === "string"
              ) {
                // handle creation of new element
                setCreateDialogOpen(true);
                setCreateDialogValue(v.inputValue);
                setCreateDialogIndex(index);
                onChange(null);
              } else {
                if (v === null) onChange(null);
                else if (typeof v === "string")
                  throw new Error("unexpected v string");
                else onChange(v.id);
              }
            }}
            value={selected}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              if (params.inputValue !== "") {
                filtered.push({
                  id: "",
                  name: `Rohstoff eintragen: "${params.inputValue}"`,
                  inputValue: params.inputValue,
                });
              }
              return filtered;
            }}
          />
        );
      }}
      name={name as keyof recipeData}
      control={control}
    />
  );
};
