import { useState, useEffect, useRef } from "react";
import { API_URL } from "../config";
import { useStore } from "../store";

export interface User {
  id: string;
  login: string;
  language: string;
  language_ID: string;
  language_native_name: string;
  loginopen?: boolean;
}

export interface AuthResponse {
  authenticated: boolean;
  user?: User;
  error?: string;
}

const useAuth = (): [boolean | null, User | undefined] => {
  const setAuthStatus = useStore((state) => state.setAuthStatus);
  const setAuth = useStore((state) => state.setAuth);
  const authenticated = useStore((state) => state.authenticated);
  const user = useStore((state) => state.user);

  const fetchState = useRef("init");

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await fetch(`${API_URL}auth/`, {
          method: "GET",
          credentials: "include", // include cookies in the request
        });
        fetchState.current = "done";
        const authResponse: AuthResponse = await response.json();
        if (authResponse.user) {
          setAuth(
            authResponse.authenticated || false,
            undefined,
            authResponse.user
          );
        } else {
          setAuth(false, authResponse.error, undefined);
        }
      } catch (error) {
        fetchState.current = "error";
        setAuth(
          false,
          error instanceof Error ? error.message : "Failed with auth",
          undefined
        );
        console.error("Error checking authentication:", error);
      }
    };

    if (fetchState.current === "init") {
      setAuthStatus("loading");
      fetchState.current = "fetching";
      checkAuth();
    }
  }, []);

  return [authenticated, user];
};

export default useAuth;
