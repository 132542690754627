import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { RecipeListData } from "./components/RecipeListData";
import { CreateRecipe } from "./components/CreateRecipe";
import { Profile } from "./components/Profile";
import { RecipeViewData } from "./components/RecipeViewData";
import { EditRecipeData } from "./components/EditRecipeData";

const router = createBrowserRouter(
  [
    {
      element: <App />,
      children: [
        {
          path: "/",
          element: <RecipeListData />,
        },
        {
          path: "/recipes/list",
          element: <RecipeListData />,
        },
        {
          path: "/recipes/new",
          element: <CreateRecipe formData={undefined} />,
        },
        {
          path: "/recipes/:recipeId/edit",
          element: <EditRecipeData />,
        },
        {
          path: "/recipes/:recipeId",
          element: <RecipeViewData />,
        },
        {
          path: "/profile",
          element: <Profile />,
        },
      ],
    },
  ],
  {
    basename: "/react/",
  }
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
