import { UseQueryResult } from "@tanstack/react-query";
import React, { useState } from "react";
import { recipeListResponse } from "./RecipeListData";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import { BASE_URL } from "../config";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Link as RouterLink } from "react-router-dom";
import { useStore } from "../store";
import Link from "@mui/material/Link";
import { RubrikFilterData } from "./RubrikFilterData";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Breakpoint, SxProps, useTheme } from "@mui/material/styles";
import { rubrikItem } from "../hooks/useRubrikQuery";
import useMediaQuery from "@mui/material/useMediaQuery";
import StarFilter from "./RecipeRating/StarFilter";

const breakpointCompact: Breakpoint = "md";

type RecipeListProps = {
  recipeQuery: UseQueryResult<recipeListResponse, unknown>;
};

export const RecipeList: React.FC<RecipeListProps> = (props) => {
  const { recipeQuery } = props;
  const [showFilter, setShowFilter] = useState(false);
  const page = useStore((state) => state.listPage);
  const itemsPerPage = useStore((state) => state.listItemsPerPage);
  const searchText = useStore((state) => state.listSearchText);
  const setSearchText = useStore((state) => state.setListSearchText);
  const setItemsPerPage = useStore((state) => state.setListItemsPerPage);
  const setPage = useStore((state) => state.setListPage);
  const setListStarMinMy = useStore((state) => state.setListStarMinMy);
  const setListStarMinAvg = useStore((state) => state.setListStarMinAvg);
  const listFilterCategoryId = useStore((state) => state.listFilterCategoryId);
  const listFilterCountryId = useStore((state) => state.listFilterCountryId);
  const listFilterGangId = useStore((state) => state.listFilterGangId);
  const listFilterLogin = useStore((state) => state.listFilterLogin);
  const listFilterStarMy = useStore((state) => state.listMinStarMy);
  const listFilterStarAvg = useStore((state) => state.listMinStarAvg);
  const theme = useTheme();
  const isCompact = useMediaQuery(theme.breakpoints.down(breakpointCompact));

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (recipeQuery.isLoading) return <div>Loading...</div>;
  if (!recipeQuery.data) return <div>no data...</div>;

  const rows = recipeQuery.data.data.filter((row) => {
    const searchPass =
      !searchText ||
      row.titel.toLowerCase().includes(searchText.toLowerCase()) ||
      (row.bemerkung &&
        row.bemerkung.toLowerCase().includes(searchText.toLowerCase())) ||
      row.ingredients.some((ingredient) =>
        ingredient.rohstoff.toLowerCase().includes(searchText.toLowerCase())
      );

    const kategoriePass =
      !listFilterCategoryId || row.kategorie_ID === listFilterCategoryId.id;

    const landPass =
      !listFilterCountryId || row.land_ID === listFilterCountryId.id;

    const gangPass = !listFilterGangId || row.gang_ID === listFilterGangId.id;

    const benutzerPass =
      !listFilterLogin || row.benutzer_ID === listFilterLogin.id;

    const starMyPass =
      !listFilterStarMy ||
      (row.myStars !== null && row.myStars >= listFilterStarMy);

    const starAvgPass =
      !listFilterStarAvg ||
      (row.avgStars !== null && row.avgStars >= listFilterStarAvg);

    return (
      searchPass &&
      kategoriePass &&
      landPass &&
      gangPass &&
      benutzerPass &&
      starMyPass &&
      starAvgPass
    );
  });

  const filteredCount = rows.length;
  const conditionalHiddenFilter: (
    filter: number | rubrikItem | null
  ) => SxProps = (rubrikFilter) => {
    return rubrikFilter === null && !showFilter
      ? { display: { xs: "none", sm: "initial" } }
      : {};
  };

  return (
    <Grid container spacing={2} sx={{ marginTop: 1 }}>
      <Grid item xs={8} sm={6} md={3}>
        <TextField
          size="small"
          fullWidth
          id="outlined-search"
          label="Suche"
          type="search"
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
            setPage(0);
          }}
        />
      </Grid>
      <Grid item xs={4} sx={{ display: { xs: "initial", sm: "none" } }}>
        <FormControlLabel
          control={
            <Switch
              value={showFilter}
              onChange={(e) => setShowFilter(e.target.checked)}
            />
          }
          label="Filter"
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={3}
        sx={conditionalHiddenFilter(listFilterCategoryId)}
      >
        <RubrikFilterData rubrik="kategorie" />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={3}
        sx={conditionalHiddenFilter(listFilterGangId)}
      >
        <RubrikFilterData rubrik="gang" />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={3}
        sx={conditionalHiddenFilter(listFilterCountryId)}
      >
        <RubrikFilterData rubrik="land" />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={3}
        sx={conditionalHiddenFilter(listFilterLogin)}
      >
        <RubrikFilterData rubrik="benutzer" />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={3}
        sx={conditionalHiddenFilter(listFilterStarMy)}
      >
        <StarFilter
          setter={setListStarMinMy}
          title="Meine Bewertung"
          value={listFilterStarMy}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={3}
        sx={conditionalHiddenFilter(listFilterStarAvg)}
      >
        <StarFilter
          setter={setListStarMinAvg}
          title="Bewertung"
          value={listFilterStarAvg}
        />
      </Grid>
      <Grid item xs={12}>
        <TablePagination
          labelRowsPerPage="pro Seite"
          rowsPerPageOptions={[10, 20, 50, 100, { label: "All", value: -1 }]}
          colSpan={4}
          component={Paper}
          count={filteredCount}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={itemsPerPage}
        />
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper} elevation={3}>
          <Table aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell>Titel</TableCell>
                <TableCell
                  align="left"
                  sx={{
                    display: { xs: "none", [breakpointCompact]: "table-cell" },
                  }}
                >
                  Bemerkung
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    display: { xs: "none", [breakpointCompact]: "table-cell" },
                  }}
                >
                  Icon
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    display: { xs: "none", [breakpointCompact]: "table-cell" },
                  }}
                >
                  Login
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    display: { xs: "none", [breakpointCompact]: "table-cell" },
                  }}
                >
                  Bewertung
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    display: { xs: "none", [breakpointCompact]: "table-cell" },
                  }}
                >
                  Zutaten
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(itemsPerPage > 0
                ? rows.slice(
                    page * itemsPerPage,
                    page * itemsPerPage + itemsPerPage
                  )
                : rows
              ).map((row) => (
                <TableRow
                  key={row.rezept_ID}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Link
                      component={RouterLink}
                      to={`/recipes/${row.rezept_ID}`}
                    >
                      {row.titel}
                    </Link>
                    {isCompact ? (
                      <span style={{ color: "gray" }}>
                        {" "}
                        {row.bemerkung}, von {row.login}
                      </span>
                    ) : null}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      display: {
                        xs: "none",
                        [breakpointCompact]: "table-cell",
                      },
                    }}
                  >
                    {row.bemerkung}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      display: {
                        xs: "none",
                        [breakpointCompact]: "table-cell",
                      },
                    }}
                  >
                    {typeof row.icon === "string" ? (
                      <img
                        width="16"
                        height="16"
                        src={`${BASE_URL}images/iconthumbs/${row.rezept_ID}.png`}
                        alt="Recipe icon"
                      />
                    ) : null}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      display: {
                        xs: "none",
                        [breakpointCompact]: "table-cell",
                      },
                    }}
                  >
                    {row.login}
                  </TableCell>{" "}
                  <TableCell
                    align="right"
                    sx={{
                      display: {
                        xs: "none",
                        [breakpointCompact]: "table-cell",
                      },
                    }}
                  >
                    {typeof row.myStars === "number" ? (
                      <span>
                        <span style={{ fontWeight: "bold" }}>
                          {row.myStars.toFixed(1)}
                        </span>{" "}
                        /{" "}
                      </span>
                    ) : (
                      ""
                    )}
                    {typeof row.avgStars === "number"
                      ? row.avgStars.toFixed(1)
                      : ""}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      display: {
                        xs: "none",
                        [breakpointCompact]: "table-cell",
                      },
                    }}
                  >
                    {row.ingredients.map((ingredient, i) => {
                      return (
                        <img
                          key={i}
                          src={`${BASE_URL}${ingredient.icon_src}`}
                          alt={ingredient.rohstoff}
                          title={ingredient.rohstoff}
                        />
                      );
                    })}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};
