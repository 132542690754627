import { UseQueryResult } from "@tanstack/react-query";
import { RubrikFilterDataProps } from "./RubrikFilterData";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { rubrikItem } from "../hooks/useRubrikQuery";
import { forwardRef } from "react";

export type RubrikFilterProps = {
  name?: string;
  rubrikQuery: UseQueryResult<rubrikItem[]>;
  rubrik: RubrikFilterDataProps["rubrik"];
  setOpen?: (open: boolean) => void;
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    newValue: rubrikItem | null
  ) => void;
  currentValue: {
    readonly id: string;
    readonly name: string;
    readonly count: number;
  } | null;
};

export const RubrikFilter = forwardRef(function RubrikFilter(
  props: RubrikFilterProps,
  ref
) {
  const { rubrikQuery, rubrik, setOpen, onChange, currentValue, name } = props;

  return (
    <Autocomplete<rubrikItem>
      ref={ref}
      size="small"
      fullWidth
      onOpen={() => {
        if (typeof setOpen !== "function") return;
        setOpen(true);
      }}
      onClose={() => {
        if (typeof setOpen !== "function") return;
        setOpen(false);
      }}
      blurOnSelect
      getOptionLabel={(option) => {
        return option.name;
      }}
      // value={currentValue ?? null}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        );
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            name={name}
            label={
              // first letter upper case
              rubrik.charAt(0).toUpperCase() + rubrik.slice(1)
            }
          />
        );
      }}
      onChange={onChange}
      loading={rubrikQuery.isLoading}
      options={rubrikQuery.data?.filter((item) => !!item.count) ?? []}
    />
  );
});
