import { API_URL } from "../config";
import { rubrikType } from "../components/RubrikFilterData";
import { useStore } from "../store";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export type rubrikItem = {
  id: string;
  name: string;
  count: number;
};

export type rubrikItemWithoutCounts = {
  id: string;
  name: string;
  /** For instant creation dialog */
  inputValue?: string;
  /** only exists for einheit */
  abbreviation?: string;
};

export const useRubrikQuery = <
  itemType extends rubrikItem | rubrikItemWithoutCounts
>(
  rubrik: rubrikType,
  enabled: boolean
) => {
  const languageId = useStore((state) => state.user?.language_ID);

  const rubrikQuery = useQuery(
    [rubrik, languageId],
    async () => {
      const response = await fetch(
        `${API_URL}${rubrik}/list.php?languageId=${languageId}&languageFilterMode=onlyCountLanguageRecipes`,
        { credentials: "include" }
      );
      const json: itemType[] = await response.json();
      return json;
    },
    {
      enabled: enabled && typeof languageId !== "undefined",
      staleTime: Infinity,
    }
  );

  return rubrikQuery;
};

export const useInvalidateRubrikQuery = (rubrik: rubrikType) => {
  const languageId = useStore((state) => state.user?.language_ID);
  const queryClient = useQueryClient();

  const doInvalidate = () => {
    queryClient.invalidateQueries([rubrik, languageId]);
  };

  return doInvalidate;
};
