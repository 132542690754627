import { useParams } from "react-router-dom";
import { RecipeView } from "./RecipeView";
import { useRecipeQuery } from "../hooks/useRecipeQuery";

export type recipeData = {
  rezept_ID: string;
  icon: string | null;
  image: string | null;
  login: string;
  vorname: string;
  nachname: string;
  datum: string;
  zubereitungszeit: string | null;
  personen: string | null;
  kalorien: string | null;
  gang: string;
  gang_LNG: string;
  gang_ID: string;
  kategorie: string;
  kategorie_ID: string;
  kategorie_LNG: string;
  land: string | null;
  land_ID: string | null;
  land_LNG: string | null;
  titel: string;
  rezept_LNG: string;
  bemerkung: string | null;
  tip: string | null;
  zubereitung: string;
  /** for example "images/iconthumbs/SSCALE_159_9.png" */
  iconInfo: string | null;
  zutaten: {
    zutatnr: string;
    /** int "2.0" */
    menge: string;
    /** "0" */
    einheit_ID: string;
    /** "105" */
    rohstoff_ID: string;
    /** Icon string */
    data: string;
    icon_ID: string;
    rohstoff: string;
    rohstoff_LNG: string;
    einheit: string | null;
    einheit_LNG: string | null;
    abbreviation: string | null;
    zusatz: string | null;
    /** "images/iconthumbs/Z92.png" */
    iconInfo: string | null;
  }[];
};

export const RecipeViewData = () => {
  const { recipeId } = useParams();

  const recipeQuery = useRecipeQuery(recipeId);

  if (typeof recipeId === "undefined") throw new Error("recipeId is undefined");

  return <RecipeView recipeId={recipeId} recipeQuery={recipeQuery} />;
};
