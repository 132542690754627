import React from "react";
import { useStore } from "../store";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Slide, { SlideProps } from "@mui/material/Slide";

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="right" />;
}

/**
 * Uses the central store. Show a message using addSnackbarMessage()
 * @returns A popup that shows a message for a short time.
 */
const AlertMessagePopup: React.FC = () => {
  const snackbarMessage = useStore((state) => state.snackbarMessage);
  const snackbarType = useStore((state) => state.snackbarType);
  const removeSnackbarMessage = useStore(
    (state) => state.removeSnackbarMessage
  );

  return (
    <Snackbar
      autoHideDuration={snackbarMessage?.duration}
      open={snackbarMessage !== null}
      onClose={() => removeSnackbarMessage()}
      message={snackbarMessage?.message}
      TransitionComponent={SlideTransition}
    >
      <Alert severity={snackbarType} onClose={() => removeSnackbarMessage()}>
        {snackbarMessage?.message}
      </Alert>
    </Snackbar>
  );
};

export default AlertMessagePopup;
