import React, { FormEvent, useState } from "react";
import { BASE_URL } from "../config";
import { useStore } from "../store";
import { AuthResponse } from "../hooks/checkAuth";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Alert, Link, Modal, SxProps } from "@mui/material";

function LoginForm() {
  const setAuth = useStore((state) => state.setAuth);
  const authStatus = useStore((state) => state.authStatus);
  const authError = useStore((state) => state.authError);

  const [showLegal, setShowLegal] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  // get URL query param ?registration=success
  const urlParams = new URLSearchParams(window.location.search);
  const registrationSuccess = urlParams.get("registration") === "success";

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await fetch(`${BASE_URL}sessionnew.php?apimode=true`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `login=${username}&passwd=${password}`,
        credentials: "include",
      });
      const json: AuthResponse = await response.json();
      if (response.ok && json.authenticated === true) {
        if (!json.user) throw new Error("User expected");
        setAuth(true, undefined, json.user);
      } else {
        setAuth(false, json.error, undefined);
      }
    } catch (e) {
      setAuth(false, e instanceof Error ? e.message : undefined, undefined);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          style={{
            marginLeft: 30,
            marginBottom: -50,
          }}
          src={`${BASE_URL}images/startgoodies/start_new/kaifood05.png`}
          width={350}
          alt="Logo"
        />
        <Typography component="h1" variant="h5">
          voellerei.com
        </Typography>
        <Typography component="h2" variant="h6" fontWeight="100">
          Kais Rezeptdatenbank
        </Typography>

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          {authStatus === "loading" ? (
            <Alert severity="info">Laden des Status...</Alert>
          ) : authStatus === "initial" ? (
            <Alert severity="info">Status wird noch nicht geladen...</Alert>
          ) : null}
          {registrationSuccess ? (
            <Alert severity="success">
              Registrierung erfolgreich! Bitte melde Dich nun an.
            </Alert>
          ) : null}
          {authError ? <Alert severity="error">{authError}</Alert> : null}
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Benutzername"
            name="username"
            autoComplete="off"
            autoFocus
            autoCapitalize="none"
            autoCorrect="off"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Passwort"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={
              authStatus === "loading" ||
              authStatus === "initial" ||
              !username ||
              !password
            }
          >
            Einloggen
          </Button>

          <Grid container>
            <Grid item xs>
              <Link
                href={`${BASE_URL}login_forgot.php?loginlanguage=de`}
                variant="body2"
              >
                Passwort vergessen
              </Link>
            </Grid>
            <Grid item>
              <Link
                href={`${BASE_URL}cookietest.php?loginlanguage=de&forwardTo=react`}
                variant="body2"
              >
                neues Konto einrichten
              </Link>
            </Grid>
            <Grid item>
              <Button
                type="button"
                variant="text"
                sx={{ margin: 0, padding: 0 }}
                onClick={() => setShowLegal(true)}
              >
                Datenschutzerklärung
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Modal open={showLegal} onClose={() => setShowLegal(false)}>
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h4">
            Datenschutzerklärung
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Eine Anmeldung (und damit Cookies) ist erforderlich, da es sich
            hierbei um ein Forum handelt, auf dem Benutzer ihre Rezepte
            eintragen können. Der Benutzer kann die eigenen Rezepte später
            wieder verändern. Das wäre ohne Passwortabfrage und Cookies nicht
            möglich - es könnte sonst jeder alle Rezepte verändern.
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Ich werde die Daten, die für die Anmeldung notwendig sind (Name,
            Vorname, Passwort und vor allem eMail) nicht an Dritte weitergeben!
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Ebenfalls werde ich Cookies nur zum Zwecke der Anmeldung verwenden -
            also dazu, dass die angezeigten Daten für den angemeldeten Nutzer
            bestimmt sind (z.B. Bearbeitbarkeit von eigenen Rezepten). Nutzer
            stimmen mit der Anmeldung zu, dass solche technisch notwendigen
            Cookies verwendet werden.
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Das Passwort wird in der Datenbank so gespeichert, dass auch ich es
            nicht auslesen kann; es wird eine Verschlüsselung verwendet, die nur
            in einer Richtung funktioniert. Der 'login'-Name wird anderen
            Besuchern der Datenbank angezeigt. Alle anderen persönlichen Daten
            bleiben selbstverständlich unsichtbar.
          </Typography>

          <Button
            type="button"
            variant="outlined"
            sx={{ mt: 2 }}
            onClick={() => setShowLegal(false)}
          >
            Schließen
          </Button>
        </Box>
      </Modal>
    </Container>
  );
}

const modalStyle: SxProps = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  overflowY: "auto",
  maxHeight: "80vh",
};

export default LoginForm;
