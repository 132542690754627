import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React, { useCallback } from "react";
import { RubrikSelectorData } from "./RubrikSelectorData";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import { recipeData } from "./RecipeViewData";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { UnitSelector } from "./UnitSelector";
import { IngredientCreateDialog } from "./IngredientCreateDialog";
import { IngredientInput } from "./IngredientInput";
import {
  Alert,
  Breakpoint,
  IconButton,
  TableFooter,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_URL } from "../config";
import { useStore } from "../store";

const breakpointMultirow: Breakpoint = "md";
/** For expandability, we need border top instead of border bottom */
const borderTop: React.CSSProperties = {
  borderBottom: "none",
  borderTop: "1px solid #ccc",
};
const expandedBorder: React.CSSProperties["borderTop"] = "3px solid #aaa";

export type CreateRecipeProps = {
  formData: recipeData | undefined;
};

export const CreateRecipe: React.FC<CreateRecipeProps> = (props) => {
  const { formData } = props;
  const [expandedRows, setExpandedRows] = React.useState<number[]>([]);
  const theme = useTheme();
  const isMultiRow = useMediaQuery(theme.breakpoints.down(breakpointMultirow));
  const addMessage = useStore((state) => state.addSnackbarMessage);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const languageId = useStore((state) => state.user?.language_ID);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    formState,
    setValue,
  } = useForm({
    defaultValues: formData,
  });

  const {
    fields: zutatenFields,
    append,
    prepend,
    remove,
    swap,
    move,
    insert,
    update,
  } = useFieldArray({
    control,
    name: "zutaten",
    rules: {
      validate: (value) => {
        if (!value.length)
          return "Die Zutatenliste darf nicht leer sein! Bitte eine Zutat hinzufügen.";
        if (value.some((zutat) => !zutat.rohstoff_ID))
          return "Die Zutatenliste darf keine leeren Zutaten enthalten";
        return true;
      },
    },
  });

  const sendForm = useMutation(async (data: recipeData) => {
    // prepare formdata from data
    const postData = new FormData();

    if (!languageId) throw new Error("No language ID available");

    for (let zIndex = 0; zIndex < data.zutaten.length; zIndex++) {
      const zutat = data.zutaten[zIndex];
      if (!zutat.rohstoff_ID) continue;
      postData.append(`z_menge[${zIndex + 1}]`, zutat.menge);
      postData.append(`z_einheit[${zIndex + 1}]`, zutat.einheit_ID);
      postData.append(`z_rohstoff[${zIndex + 1}]`, zutat.rohstoff_ID);
      postData.append(`z_zusatz[${zIndex + 1}]`, zutat.zusatz ?? "");
    }

    if (typeof formData !== "undefined")
      postData.append("change_ID", data.rezept_ID);
    postData.append("titel", data.titel);
    postData.append("kalorien", data.kalorien ?? "");
    postData.append("bemerkung", data.bemerkung ?? "");
    postData.append("zubereitung", data.zubereitung);
    postData.append("tip", data.tip ?? "");
    postData.append("zubereitungszeit", data.zubereitungszeit ?? "");
    postData.append("personen", data.personen ?? "");
    postData.append("kategorie_ID", getRubrikItemSelected(data, "kategorie"));
    postData.append("land_ID", getRubrikItemSelected(data, "land"));
    postData.append("gang", getRubrikItemSelected(data, "gang"));
    postData.append("rezeptsprache", data.rezept_LNG ?? languageId);

    const response = await fetch(`${API_URL}recipes/post.php`, {
      credentials: "include",
      method: "POST",
      body: postData,
    });

    const jsonResponse:
      | {
          success: true;
          mode: "create" | "change" | "translate";
          id: string | number;
        }
      | { success: false; error: string } = await response.json();

    if (jsonResponse.success) return jsonResponse;
    else throw new Error(jsonResponse.error);
  });

  const onSubmit: SubmitHandler<recipeData> = useCallback(
    (e) => {
      sendForm.mutate(e, {
        onSuccess: (response) => {
          let message: string;
          switch (response.mode) {
            case "create":
              message = "Rezept erfolgreich erstellt";
              break;
            case "change":
              message = "Rezept erfolgreich geändert";
              break;
            case "translate":
              message = "Rezept erfolgreich übersetzt";
              break;
            default:
              message = `Rezept erfolgreich gespeichert (UNBEKANNTER MODE ${
                response.mode ?? "undefined"
              })`;
          }
          const queryKey = [
            "recipe",
            formData?.rezept_LNG,
            response.id.toString(),
          ];
          queryClient.invalidateQueries(queryKey);
          addMessage(message, 5000, "success");
          navigate(`/recipes/${response.id}`);
        },
        onError: (error) => {
          if (error instanceof Error) {
            addMessage(
              `Fehler beim Speichern: ${error.message}`,
              10000,
              "error"
            );
          } else {
            console.error(error);
            addMessage(
              `Ein unbekannter Fehler ist beim Speichern aufgetreten`,
              10000,
              "error"
            );
          }
        },
      });
    },
    [addMessage, sendForm]
  );

  return (
    <Grid
      container
      spacing={2}
      sx={{ marginTop: 1 }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid item xs={12}>
        <TextField
          {...register("titel", { required: true })}
          error={Boolean(errors.titel)}
          helperText={errors.titel && "Bitte dem Rezept einen Titel geben"}
          label="Titel"
          fullWidth
          size="small"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          {...register("bemerkung")}
          label="Bemerkung"
          fullWidth
          multiline
          maxRows={3}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <RubrikSelectorData control={control} rubrik="kategorie" />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <RubrikSelectorData control={control} rubrik="gang" />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <RubrikSelectorData control={control} rubrik="land" />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <TextField
          {...register("kalorien")}
          label="Kalorien"
          fullWidth
          size="small"
          InputProps={{
            endAdornment: <InputAdornment position="end">kcal</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          {...register("personen")}
          label="Personen"
          fullWidth
          size="small"
          type="number"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          {...register("zubereitungszeit")}
          label="Zubereitungszeit"
          fullWidth
          size="small"
          type="number"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">Minuten</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          {...register("zubereitung", { required: true })}
          label="Zubereitung"
          error={Boolean(errors.zubereitung)}
          helperText={errors.zubereitung && "Bitte die Zubereitung beschreiben"}
          fullWidth
          multiline
          // minRows={3}
          rows={12}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          {...register("tip")}
          label="Tip"
          fullWidth
          multiline
          // minRows={2}
          rows={6}
        />
      </Grid>
      <Grid item xs={12}>
        <Table sx={{}} aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  width: "10px",
                  display: { xs: "none", lg: "table-cell" },
                }}
              >
                Nr.
              </TableCell>
              <TableCell
                sx={{ width: { xs: 40, [breakpointMultirow]: 100 } }}
                align="right"
              >
                Menge
              </TableCell>
              <TableCell sx={{ width: { xs: 40, [breakpointMultirow]: 100 } }}>
                Einheit
              </TableCell>
              <TableCell>Rohstoff</TableCell>
              <TableCell
                sx={{
                  display: { xs: "none", [breakpointMultirow]: "table-cell" },
                }}
              >
                Anmerkung
              </TableCell>
              <TableCell
                sx={{
                  width: 40,
                  display: { xs: "none", [breakpointMultirow]: "table-cell" },
                }}
              >
                Del
              </TableCell>
              <TableCell
                sx={{
                  width: 10,
                  display: { xs: "table-cell", [breakpointMultirow]: "none" },
                }}
              >
                xp
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {zutatenFields.map((field, index, arr) => {
              return (
                <React.Fragment key={field.id}>
                  <TableRow
                    sx={
                      expandedRows.includes(index)
                        ? {
                            borderTop: {
                              xs: expandedBorder,
                              [breakpointMultirow]: "none",
                            },
                          }
                        : {}
                    }
                  >
                    <TableCell
                      sx={{
                        ...borderTop,
                        display: { xs: "none", lg: "table-cell" },
                      }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell align="right" sx={borderTop}>
                      <TextField
                        {...register(`zutaten.${index}.menge` as const)}
                        defaultValue={field.menge}
                        size="small"
                        type="number"
                        variant="standard"
                      />
                    </TableCell>
                    <TableCell sx={borderTop}>
                      <UnitSelector
                        {...register(`zutaten.${index}.einheit_ID` as const)}
                        defaultValue={field.einheit_ID}
                      />
                    </TableCell>
                    <TableCell sx={{ ...borderTop, width: 300 }}>
                      <IngredientInput
                        control={control}
                        index={index}
                        name={`zutaten.${index}.rohstoff_ID` as const}
                      />
                    </TableCell>
                    {!isMultiRow && (
                      // complex, but necessary as otherwise, we would have TWO zusatz fields that would conflict
                      // in case the user did expand the row, enter a zusatz (in the second row text field, which is another one than this)
                      // and then collapse again and enter something in this zusatz field
                      <TableCell
                        sx={{
                          ...borderTop,
                          display: {
                            xs: "none",
                            [breakpointMultirow]: "table-cell",
                          },
                        }}
                      >
                        <TextField
                          {...register(`zutaten.${index}.zusatz` as const)}
                          defaultValue={field.zusatz}
                          size="small"
                          variant="standard"
                          fullWidth
                        />
                      </TableCell>
                    )}
                    <TableCell
                      sx={{
                        ...borderTop,
                        display: {
                          xs: "none",
                          [breakpointMultirow]: "table-cell",
                        },
                      }}
                    >
                      <IconButton
                        onClick={() => remove(index)}
                        color="secondary"
                        size="small"
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell
                      sx={{
                        ...borderTop,
                        display: {
                          xs: "table-cell",
                          [breakpointMultirow]: "none",
                        },
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          // toggle expanded for this row
                          setExpandedRows(
                            expandedRows.includes(index)
                              ? expandedRows.filter((i) => i !== index)
                              : [...expandedRows, index]
                          );
                        }}
                        color="secondary"
                        size="small"
                      >
                        {expandedRows.includes(index) ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  {expandedRows.includes(index) && isMultiRow && (
                    <TableRow
                      sx={{
                        display: {
                          xs: "table-row",
                          [breakpointMultirow]: "none",
                        },
                        borderBottom: expandedBorder,
                      }}
                    >
                      <TableCell>&nbsp;</TableCell>
                      <TableCell colSpan={2}>
                        <TextField
                          {...register(`zutaten.${index}.zusatz` as const)}
                          defaultValue={field.zusatz}
                          size="small"
                          variant="standard"
                          label="Anmerkung"
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => {
                            // collapse this row
                            setExpandedRows(
                              expandedRows.filter((i) => i !== index)
                            );
                            remove(index);
                          }}
                          color="secondary"
                          size="small"
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={isMultiRow ? 2 : 3}>
                <Button
                  onClick={() =>
                    append({
                      zutatnr: "",
                      menge: "",
                      einheit_ID: "0",
                      data: "",
                      icon_ID: "",
                      rohstoff_ID: "",
                      rohstoff: "",
                      rohstoff_LNG: "",
                      einheit: null,
                      einheit_LNG: null,
                      abbreviation: null,
                      zusatz: "",
                      iconInfo: null,
                    })
                  }
                  variant="outlined"
                  color="primary"
                >
                  Zutat hinzufügen
                </Button>
              </TableCell>
              <TableCell colSpan={isMultiRow ? 2 : 3}>
                {Boolean(errors.zutaten) && (
                  <Alert severity="error">
                    {errors.zutaten?.root?.message}
                  </Alert>
                )}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={{ mr: 5 }}
        >
          Speichern
        </Button>
        {typeof formData?.rezept_ID !== "undefined" && (
          <Link
            type="button"
            component={RouterLink}
            to={`/recipes/${formData?.rezept_ID}`}
          >
            Abbrechen
          </Link>
        )}
      </Grid>
      <IngredientCreateDialog
        registerNewIngredient={(index, value) => {
          update(index, {
            ...zutatenFields[index],
            ...value,
          });
        }}
      />
    </Grid>
  );
};

/** Hacky way but seems necessary, as the MUI autocomplete selector (in RubrikSelector)
 * changes the string value of the rubrik item (like kategorie = "Backwaren") to an object of
 * the selection, like {id: 1, name: "Backwaren"}.
 */
function getRubrikItemSelected(
  data: recipeData,
  rubrikName: "gang" | "kategorie" | "land"
): string {
  const value = data[rubrikName];
  const unchangedId = data[`${rubrikName}_ID`];
  // if the value is (still) a string, this means it was not changed (to an object). use the original id
  if (typeof value === "string") return unchangedId ?? "";
  else if (typeof value === "object" && value !== null && "id" in value)
    return (value as { id: string | number }).id.toString();
  else return "";
}
