import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./App.css";
import useAuth from "./hooks/checkAuth";
import Login from "./components/Login";
import { Layout } from "./components/Layout";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import ScrollToTop from "./components/ScrollToTop";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const [authenticated] = useAuth();

  return (
    <QueryClientProvider client={queryClient}>
      <ScrollToTop />
      {authenticated === true ? <Layout /> : <Login />}
    </QueryClientProvider>
  );
}

export default App;
