import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";
import StarIcon from "@mui/icons-material/Star";

interface StarFilterProps {
  setter: (minStar: number | null) => void;
  title: string;
  value: number | null;
}

const StarFilter: React.FC<StarFilterProps> = ({ setter, title, value }) => {
  // Implement your component logic here

  return (
    <FormControl fullWidth>
      <InputLabel size="small" id={title}>
        {title}
      </InputLabel>
      <Select
        size="small"
        labelId={title}
        id={`select_${title}`}
        value={value ?? ""}
        label={title}
        onChange={(event) => {
          setter(
            event.target.value === "" ? null : (event.target.value as number)
          );
        }}
      >
        <MenuItem value={""}>Egal</MenuItem>
        <MenuItem value={1}>
          <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
        </MenuItem>
        <MenuItem value={2}>
          <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
          <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
        </MenuItem>
        <MenuItem value={3}>
          <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
          <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
          <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
        </MenuItem>
        <MenuItem value={4}>
          <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
          <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
          <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
          <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
        </MenuItem>
        <MenuItem value={5}>
          <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
          <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
          <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
          <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
          <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default StarFilter;
