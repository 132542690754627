import { useParams } from "react-router-dom";
import { useRecipeQuery } from "../hooks/useRecipeQuery";
import { CreateRecipe } from "./CreateRecipe";

export const EditRecipeData = () => {
  const { recipeId } = useParams();

  const recipeQuery = useRecipeQuery(recipeId);

  if (typeof recipeId === "undefined") {
    throw new Error("recipeId is undefined");
  }

  if (recipeQuery.isLoading) {
    return <div>Loading...</div>;
  }

  if (recipeQuery.isError) {
    return (
      <div>
        {recipeQuery.error instanceof Error
          ? recipeQuery.error.message
          : "ERROR"}
      </div>
    );
  }

  if (recipeQuery.isSuccess) {
    return <CreateRecipe formData={recipeQuery.data} />;
  }

  return <div>ERROR: unknown state: {recipeQuery}</div>;
};
