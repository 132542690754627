import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { recipeData } from "./RecipeViewData";
import { BASE_URL } from "../config";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { IngredientAmount } from "./IngredientAmount";
import { Breakpoint, Typography, useMediaQuery, useTheme } from "@mui/material";

const breakpointCompact: Breakpoint = "md";

type IngredientTableProps = {
  recipe: recipeData;
};

export const IngredientTable: React.FC<IngredientTableProps> = (props) => {
  const { recipe } = props;
  const theme = useTheme();
  const isCompact = useMediaQuery(theme.breakpoints.down(breakpointCompact));

  const [personen, setPersonen] = useState<number | undefined>(
    typeof recipe.personen === "string" && parseInt(recipe.personen) > 0
      ? parseInt(recipe.personen)
      : undefined
  );
  const [percentage, _setPercentage] = useState<number>(100);

  const setPercentage = (_percentage: string) => {
    let percentage = parseFloat(_percentage);
    if (percentage < 0) percentage = 0;
    _setPercentage(percentage);
    if (typeof recipe.personen === "string" && parseInt(recipe.personen) > 0) {
      setPersonen((percentage / 100) * parseInt(recipe.personen ?? "0"));
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">Zutaten</Typography>
      </Grid>
      {typeof personen === "number" ? (
        <Grid item xs={6} md={3}>
          <TextField
            label="Personen"
            type="number"
            value={personen}
            onChange={(e) => {
              setPersonen(parseInt(e.target.value));
              _setPercentage(
                (parseInt(e.target.value) / parseInt(recipe.personen ?? "0")) *
                  100
              );
            }}
          />
        </Grid>
      ) : null}
      <Grid item xs={6} md={3}>
        <TextField
          label="Prozent"
          type="number"
          // show up to 2 decimal places, but do not show unnecessary zeros
          value={percentage.toFixed(2).replace(/\.?0+$/, "")}
          onChange={(e) => {
            setPercentage(e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Table
          sx={{ maxWidth: "600px", width: "100%" }}
          aria-label="simple table"
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell>Menge</TableCell>
              <TableCell
                sx={{
                  display: { xs: "none", [breakpointCompact]: "table-cell" },
                }}
              >
                Einheit
              </TableCell>
              <TableCell
                sx={{
                  display: { xs: "none", [breakpointCompact]: "table-cell" },
                }}
              >
                Icon
              </TableCell>
              <TableCell>Zutat</TableCell>
              <TableCell
                sx={{
                  display: { xs: "none", [breakpointCompact]: "table-cell" },
                }}
              >
                Zusatz
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {recipe.zutaten.map((zutat, z) => (
              <TableRow key={z}>
                <TableCell>
                  <IngredientAmount
                    rohstoff={zutat.rohstoff}
                    amount={zutat.menge}
                    setPercentage={setPercentage}
                    percentage={percentage}
                  />
                  {isCompact ? ` ${zutat.abbreviation ?? ""}` : ""}
                </TableCell>
                <TableCell
                  sx={{
                    display: { xs: "none", [breakpointCompact]: "table-cell" },
                  }}
                >
                  {zutat.einheit}
                </TableCell>
                <TableCell
                  sx={{
                    display: { xs: "none", md: "table-cell" },
                  }}
                >
                  {typeof zutat.iconInfo === "string" ? (
                    <img
                      src={`${BASE_URL}${zutat.iconInfo}`}
                      alt={zutat.rohstoff}
                    />
                  ) : null}
                </TableCell>
                <TableCell>
                  {zutat.rohstoff}
                  {isCompact ? (
                    <span style={{ color: "gray", fontStyle: "italic" }}>
                      {" "}
                      {zutat.zusatz}
                    </span>
                  ) : (
                    ""
                  )}
                </TableCell>
                <TableCell
                  sx={{
                    display: { xs: "none", [breakpointCompact]: "table-cell" },
                  }}
                >
                  {zutat.zusatz}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </>
  );
};
