import { LogoutButton } from "./LogoutButton";
import Container from "@mui/material/Container";
import Tabs from "@mui/material/Tabs";
import LinkHref from "@mui/material/Link";
import Tab from "@mui/material/Tab";
import { Link, Outlet, matchPath, useLocation } from "react-router-dom";
import { BASE_URL } from "../config";
import AlertMessagePopup from "./AlertMessagePopups";

function useRouteMatch(patterns: readonly string[]) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

export const Layout = () => {
  let newRecipeLabel = "Neues Rezept";
  const routeMatch = useRouteMatch([
    "/",
    "/recipes/new",
    "/recipes/list",
    "/recipes/:recipeId/edit",
    "/recipes/:recipeId",
    "/profile",
  ]);
  let value: string | undefined | false = routeMatch?.pattern.path;
  if (value === "/recipes/:recipeId" || value === "/") value = "/recipes/list";
  if (value === "/recipes/:recipeId/edit") {
    value = "/recipes/new";
    newRecipeLabel = "Rezept bearbeiten";
  }

  return (
    <Container>
      <AlertMessagePopup />
      <Tabs variant="scrollable" value={value} aria-label="voellerei tabs">
        <LogoutButton />
        <Tab
          label="V1"
          value="/"
          component={LinkHref}
          href={`${BASE_URL}index2.php`}
        />
        <Tab
          label={newRecipeLabel}
          value="/recipes/new"
          to="/recipes/new"
          component={Link}
        />
        <Tab
          label="Rezepte"
          value="/recipes/list"
          to="/recipes/list"
          component={Link}
        />
        <Tab label="Profil" value="/profile" to="/profile" component={Link} />
      </Tabs>
      <Outlet />
    </Container>
  );
};
