import React from "react";
import useRecipeRating from "./useRecipeRating";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import { Typography } from "@mui/material";

function getLabelForRating(rating: number | null): string {
  if (rating === null) return "Keine Wertung";
  if (rating > 4.5) return "Ultra lecker";
  else if (rating >= 4) return "Lecker";
  else if (rating > 3.5) return "Ziemlich Lecker";
  else if (rating >= 3) return "OK";
  else if (rating > 2.5) return "fast OK";
  else if (rating >= 2) return "Es muss halt";
  else if (rating > 1.5) return "Bevor man verhungert";
  else if (rating >= 1) return "Gefahr für Leib und Leben";
  else return "Keine Wertung";
}

type RecipeRatingAverageProps = {
  recipeId: string;
};

const RecipeRatingAverage: React.FC<RecipeRatingAverageProps> = ({
  recipeId,
}) => {
  const recipeRatingQuery = useRecipeRating(recipeId);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Rating
        readOnly
        sx={{ ml: 2 }}
        name="hover-feedback"
        value={recipeRatingQuery.data?.avgStars ?? null}
        disabled={recipeRatingQuery.isLoading}
        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
      />
      {recipeRatingQuery.isSuccess && (
        <Box sx={{ ml: 2 }}>
          <Typography variant="body2" component="span">
            Durchschnitt aus{" "}
            {recipeRatingQuery.data?.starCount === 1
              ? "einer Bewertung"
              : `${recipeRatingQuery.data?.starCount ?? "?"} Bewertungen:`}{" "}
            {typeof recipeRatingQuery.data.avgStars === "number"
              ? `${recipeRatingQuery.data.avgStars.toFixed(1)} ~`
              : ""}
          </Typography>{" "}
          {getLabelForRating(recipeRatingQuery.data.avgStars)}
        </Box>
      )}
    </Box>
  );
};

export default RecipeRatingAverage;
