import { useMutation } from "@tanstack/react-query";
import { BASE_URL } from "../config";
import { AuthResponse } from "./checkAuth";
import { useStore } from "../store";
import { useState } from "react";

export const useLogout = () => {
  const setAuth = useStore((state) => state.setAuth);
  const setAuthStatus = useStore((state) => state.setAuthStatus);
  const authStatus = useStore((state) => state.authStatus);

  const logoutMutator = useMutation(
    async () => {
      setAuthStatus("loading");
      const response = await fetch(`${BASE_URL}logout.php?apimode=true`, {
        method: "POST",
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error("Logout failed");
      }

      const json: AuthResponse = await response.json();

      if (json.authenticated !== false)
        throw new Error("Failed to logout. Authenticated was still true");

      return true;
    },
    {
      onSuccess: () => {
        setAuth(false, undefined, undefined);
      },
    }
  );

  return {
    logoutNow: logoutMutator.mutate,
    loading: authStatus === "loading" || logoutMutator.isLoading,
  };
};
