import {
  rubrikItem,
  rubrikItemWithoutCounts,
  useRubrikQuery,
} from "../hooks/useRubrikQuery";
import { rubrikType } from "./RubrikFilterData";
import { RubrikSelector } from "./RubrikSelector";
import { recipeData } from "./RecipeViewData";
import { Control, UseFormRegister } from "react-hook-form";
import { FilterOptionsState } from "@mui/material/useAutocomplete";

export type RubrikSelectorDataProps = {
  rubrik: rubrikType;
  control: Control<recipeData, any>;
  /** Set to false if we do not want a label created from the name */
  addLabel?: boolean;
  filterOptions?:
    | ((
        options: rubrikItemWithoutCounts[],
        state: FilterOptionsState<rubrikItemWithoutCounts>
      ) => rubrikItemWithoutCounts[])
    | undefined;
};

export const RubrikSelectorData: React.FC<RubrikSelectorDataProps> = (
  props
) => {
  const { rubrik, control, addLabel = true, filterOptions } = props;
  const rubrikQuery = useRubrikQuery<rubrikItem>(rubrik, true);

  if (rubrikQuery.isLoading) {
    return <div>Loading...</div>;
  } else if (rubrikQuery.isError) {
    return (
      <div>
        Error:{" "}
        {rubrikQuery.error instanceof Error
          ? rubrikQuery.error.message
          : `Konnte keine Daten für ${rubrik} laden.`}
      </div>
    );
  }

  return (
    <RubrikSelector
      control={control}
      name={rubrik as keyof recipeData}
      options={rubrikQuery.data ?? []}
      addLabel={addLabel}
      filterOptions={filterOptions}
    />
  );
};
