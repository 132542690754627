import Rating from "@mui/material/Rating";
import React from "react";
import StarIcon from "@mui/icons-material/Star";
import Box from "@mui/material/Box";
import useRecipeRating, { starResponse } from "./useRecipeRating";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_URL } from "../../config";
import { useStore } from "../../store";
import Typography from "@mui/material/Typography";

const labels: { [index: string]: string } = {
  null: "Keine Wertung",
  1: "Geht gar nicht",
  2: "Es muss halt",
  3: "OK",
  4: "Lecker",
  5: "Ultra lecker",
};

type RecipeRatingEditorProps = {
  recipeId: string;
};

const RecipeRatingEditor: React.FC<RecipeRatingEditorProps> = ({
  recipeId,
}) => {
  const addMessage = useStore((state) => state.addSnackbarMessage);
  const user = useStore((state) => state.user);
  const recipeRatingQuery = useRecipeRating(recipeId);
  const queryClient = useQueryClient();
  const ratingMutator = useMutation(
    async (newRating: number | null) => {
      const response = await fetch(`${API_URL}stars/post.php`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify({
          rezept_ID: recipeId,
          benutzer_ID: user?.id,
          stars: newRating,
        }),
      });

      const jsonResponse: { success: false; error: string } | starResponse =
        await response.json();

      if (!jsonResponse.success) {
        throw new Error(jsonResponse.error);
      }

      queryClient.setQueryData(
        ["recipeRating", jsonResponse.rezept_ID.toString()],
        jsonResponse
      );
      return true;
    },
    {
      onError: (e) => {
        if (e instanceof Error) {
          addMessage(e.message, 5000, "error");
        } else {
          addMessage("An unknown error occurred", 5000, "error");
        }
      },
    }
  );

  const [hover, setHover] = React.useState(-1);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Rating
        sx={{ ml: 2 }}
        name="hover-feedback"
        value={recipeRatingQuery.data?.myStars ?? null}
        disabled={recipeRatingQuery.isLoading || ratingMutator.isLoading}
        onChange={(event, newValue) => {
          ratingMutator.mutate(newValue);
        }}
        onChangeActive={(event, newHover) => {
          setHover(newHover);
        }}
        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
      />
      {recipeRatingQuery.isSuccess && (
        <Box sx={{ ml: 2 }}>
          <Typography variant="body2" component="span">
            Deine Wertung:
          </Typography>{" "}
          {
            labels[
              hover !== -1 ? hover : recipeRatingQuery.data.myStars ?? "null"
            ]
          }
        </Box>
      )}
    </Box>
  );
};

export default RecipeRatingEditor;
